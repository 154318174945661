/**
 * system Created by HuZiqiang on 2022/5/17.
 */
export default [
  {
    path: '/workbench/task',
    name: 'task',
    meta: {
      requireAuth: false,
      group: '工作台',
      title: '我的任务'
    },
    component: () => import('@/views/workbench/task/myTaskList')
  },
  {
    path: '/workbench/message',
    name: 'message',
    meta: {
      requireAuth: false,
      group: '工作台',
      title: '消息列表'
    },
    component: () => import('@/views/workbench/message/list')
  },
  {
    path: '/workbench/message/detail',
    name: 'messageDetail',
    meta: {
      requireAuth: false,
      group: '工作台',
      parentName: 'message',
      title: '详情'
    },
    component: () => import('@/views/workbench/message/detail')
  },
  {
    path: '/companyManagement/capital',
    name: 'capital',
    meta: {
      requireAuth: false,
      group: '工作台',
      title: '企业信息'
    },
    component: () => import('@/views/companyManagement/capital/detail')
  },
  {
    path: '/productManagement/product/detail',
    name: 'productDetail',
    meta: {
      requireAuth: false,
      group: '工作台',
      parentName: 'capital',
      title: '详情'
    },
    component: () => import('@/views/productManagement/product/detail')
  }
]
