import axios from './http'

const api = {
  base: {
    // 登录
    goLogin: data => axios.put('/app/b/v1/user/login/menu/feature', data),
    // 退出登录
    logOut: () => axios.put('/app/b/v1/user/logout'),
    // 获取菜单
    getMenu: () => axios.get('/app/b/v1/user/menu/feature/list'),
    // 获取全部功能
    getFeature: () => axios.get('/app/b/v1/user/feature/alllist'),
    // 获取验证码图片
    getImg: () => axios.get('/app/b/v1/user/login/get/image/code'),
    // platform 注册获取验证码
    getVerify: params => axios.get('/platform/partner-basic/sendMsg', { params: params })
  },
  open: {
    downloadP: data => axios.post('/capital/open/downloadP', data, { responseType: 'blob'}),
    sendEmailCode: (email, type) => axios.post(`/capital/open/sendEmailCode?email=${email}&type=${type}`),
    sendCustomSmsCode: (mobile, type, password) => axios.post(`/capital/open/sendCustomSmsCode?mobile=${mobile}&type=${type}&password=${password}`),
    sendSmsCode: data => axios.post('/capital/open/sendCustomSmsCode', data),
    sendEmailTemplate: data => axios.post('/capital/b/p/access/info/sendEmailTemplate', data),
    validPhoneCode: (mobile, smsCode, type) => axios.post(`/capital/open/validPhoneCode?mobile=${mobile}&smsCode=${smsCode}&type=${type}`),
    forget: data => axios.post('/capital/open/forget', data),
    doContractAndSign: data => axios.post('/capital/open/doContractAndSign', data),
    sign005: data => axios.post('/capital/open/sign005', data),
    verifyPKCS7: data => axios.post('/capital/open/verifyPKCS7', data),
    forgetEmail: (phone, email, emailCode, newPassword, confirmNewPassword ) => axios.post(`/capital/open/forgetEmail?phone=${phone}&email=${email}&emailCode=${emailCode}&newPassword=${newPassword}&confirmNewPassword=${confirmNewPassword}`),
  },
  event: {
    getListPage: (act, params) => axios.get('/capital/b/p/event/page?act=' + act, { params: params }),
    // 待办
    waitTodoList: params => axios.get('/capital/b/p/event/page/1', { params: params }),
    // 已办
    completeTodoList: params => axios.get('/capital/b/p/event/page/2', { params: params }),
    getEventList: params => axios.get('/capital/b/p/event/type/list', { params: params })
  },
  workbench: {
    pageList: params => axios.get('/capital/b/p/information/listPage', { params: params }),
    countMsg: params => axios.get('/capital/b/p/information/countMsg', { params: params }),
    readAll: data => axios.put('/capital/b/p/information/readAll', data),
    readById: id => axios.put('/capital/b/p/information/readById/' + id),
    workbenchDetail: id => axios.get('/capital/b/p/information/detailById/' + id)
  },
  company: {
    capital: {
      addCoreCapAmount: (coreCode, data) => axios.post('/capital/b/p/core/cap/amount/add?coreCode=' + coreCode, data),
      removeCoreCapAmount: (coreCode, relId) => axios.post('/capital/b/p/core/cap/amount/remove?coreCode=' + coreCode + '&relId=' + relId),
      addCoreCapProd: (coreCode, data) => axios.post('/capital/b/p/core/cap/product/add?coreCode=' + coreCode, data),
      removeCoreCapProd: (coreCode, relId) => axios.post('/capital/b/p/core/cap/product/remove?coreCode=' + coreCode + '&relId=' + relId),
      pageList: params => axios.get('/capital/b/p/cap/page', { params: params }),
      getCapList: params => axios.get('/capital/b/p/cap/list', { params: params }),
      addCap: data => axios.post('/capital/b/p/cap/add', data),
      getCapDetail: params => axios.get('/capital/b/p/cap/details', { params: params }),
      getCapChildLevel: capId => axios.get('/capital/b/p/cap/details/parentChildLevel?capId=' + capId),
      capAlt: data => axios.post('/capital/b/p/cap/alt', data),
      updateCap: data => axios.post('/capital/b/p/cap/update', data),
      enableCap: (capCode, enable) => axios.post('/capital/b/p/cap/enable?capCode=' + capCode + '&enable=' + enable),
      cancelCapAdmin: data => axios.post('/capital/b/p/cap/user/admin/cancel', data),
      setCapAdmin: data => axios.post('/capital/b/p/cap/user/admin/set', data),
      getUserList: params => axios.get('/capital/b/p/cap/user/list', { params: params }),
      getCapUserPage: params => axios.get('/capital/b/p/cap/user/page', { params: params }),
      getFirmCityList: params => axios.get('/capital/b/p/cap/details/firmCityList', { params: params }),
      firmCitySave: (capId, data) => axios.post('/capital/b/p/cap/details/firmCitySave?capId=' + capId, data),
      getCreditPage: params => axios.get('/capital/b/p/cap/details/creditPage', { params: params }),
      getCoreCapProductList: params => axios.get('/capital/b/p/product/list_info', { params: params }),
    },
    customer: {
      // 获取企业信息列表
      pageList: params => axios.get('/capital/b/p/firm/info/pageList', { params: params }),
      customerDetail: id => axios.get('/capital/b/p/firm/info/detail/' + id),
      audit: (id, type, remark) => axios.post(`/capital/b/p/firm/info/audit?id=${id}&type=${type}&remark=${remark}`),
    }
  },
  quota: {
    customer: {
      pageList: params => axios.get('/capital/b/p/quota/quotaPage', { params: params }),
      quotaPageSum: params => axios.get('/capital/b/p/quota/quotaPageSum', { params: params }),
      // 导出
      exportFile: params => axios.get('/capital/b/p/quota/export',{ params, responseType: 'blob' }),
    },
    core: {
      auditQuota: (applyStatus, passAmount, deadline, id, opinion) => axios.post(`/capital/b/p/quota/audit?applyStatus=${applyStatus}&passAmount=${passAmount}&deadline=${deadline}&id=${id}&opinion=${opinion}`),
      quotaDetail: id => axios.get('/capital/b/p/quota/detail?id=' + id),
      getSurplusAmount: params => axios.get('/capital/b/p/quota/listAmountPage', { params: params }),
      pageList: params => axios.get('/capital/b/p/quota/listPage', { params: params }),
    }
  },
  credit: {
    pageList: params => axios.get('/capital/b/p/credit/page', { params: params }),
    complete: (taskId, data) => axios.post('/capital/b/p/work/creditComplete?taskId=' + taskId, data),
    creditDetails: params => axios.get('/capital/b/p/credit/details', { params: params }),
    // 导出
    exportFile: params => axios.get('/capital/b/p/credit/export',{ params, responseType: 'blob' }),
  },
  use: {
    pageList: params => axios.get('/capital/b/p/use/page', { params: params }),
    useDetails: params => axios.get('/capital/b/p/use/details', { params: params }),
    complete: (taskId, data) => axios.post('/capital/b/p/work/useComplete?taskId=' + taskId, data),
    // 试算用款期限
    timePlus: params => axios.get('/capital/b/p/use/timePlus', { params: params }),
    // 导出
    exportFile: params => axios.get('/capital/b/p/use/export',{ params, responseType: 'blob' }),
    // 根据code导出
    theCodeExportFile: useCode => axios.get('/capital/b/p/use/exportDetails?useCode=' + useCode,{ responseType: 'blob' })
  },
  eAccount: {
    makeLoans: data => axios.post('/capital/b/p/eaccount/makeLoans', data),
    getAccountInfoByNumber: eAccountNumber => axios.get('/capital/b/p/eaccount/getAccountInfoByNumber?eAccountNumber=' + eAccountNumber),
  },
  payBack: {
    exportFile: params => axios.get('/capital/b/p/repayment/export',{ params, responseType: 'blob' }),
    pageList: params => axios.get('/capital/b/p/repayment/page', { params: params }),
    payBackDetails: repaymentCode => axios.get('/capital/b/p/repayment/details?repaymentCode=' + repaymentCode),
    getRecordPage: params => axios.get('/capital/b/p/repayment/recordPage', { params: params }),
    getTrial: params => axios.get('/capital/b/p/repayment/getTrial', { params: params }),
    donePayStoneBase: data => axios.put('/capital/b/p/repayment/donePayStoneBase', data),
    donePayConfirm: data => axios.put('/capital/b/p/repayment/donePayConfirm', data),
    // 单期
    exportRecord: repaymentCode => axios.get('/capital/b/p/repayment/exportRecord?repaymentCode=' + repaymentCode,{ responseType: 'blob' }),
    listRecord: params => axios.get('/capital/b/p/repayment/listRecord', { params: params }),
    recordDetails: recordId => axios.get('/capital/b/p/repayment/recordDetails?recordId=' + recordId),
    // 多期
    exportPlan: repaymentCode => axios.get('/capital/b/p/repayment/exportPlan?repaymentCode=' + repaymentCode,{ responseType: 'blob' }),
    listPlan: params => axios.get('/capital/b/p/repayment/listPlan', { params: params }),
    listRecordDto: planId => axios.get('/capital/b/p/repayment/listRecordDto?planId=' + planId),
  },
  contract: {
    template: {
      pageList: params => axios.get('/capital/b/p/contract/template/listPage', { params: params }),
      getTemplateCodeList: data => axios.post('/capital/b/p/contract/template/codeList', data),
      contractDetails: contractId => axios.get('/capital/b/p/contract/template/getInfoById/' + contractId ),
      getTemplateList: params => axios.get('/capital/b/p/contract/template/list', { params: params }),
      addTemplate: data => axios.post('/capital/b/p/contract/template/add', data),
      updateTemplate: data => axios.post('/capital/b/p/contract/template/update', data),
    },
    sign: {
      getDetail: contractId => axios.get('/capital/b/p/contract/produce/details/' + contractId ),
      altOfflineSign: data => axios.post('/capital/b/p/contract/produce/altOfflineSign', data),
      pageList: params => axios.get('/capital/b/p/contract/produce/signList', { params: params }),
    },
    isContractSign: params => axios.get('/capital/b/p/contract/produce/isContractSign', { params: params }),
  },
  product: {
    product: {
      // 金融产品列表
      pageList: params => axios.get('/capital/b/p/product/page?type=1', { params: params }),
      add: data => axios.post('/tecapitalnant/b/p/product/add', data),
      update: data => axios.post('/capital/b/p/product/update', data),
      productDel: productCode => axios.get('/capital/b/p/product/del?productCode=' + productCode),
      altProductStatus: (productCode , state, opinion) => axios.get(`/capital/b/p/product/altProductStatus?productCode=${productCode }&state=${state}&opinion=${opinion}`),
      altProductState: (productCode , String) => axios.get(`/capital/b/p/product/altProductState?productCode=${productCode}&String=${String}`),
      getDetail: params => axios.get('/capital/b/p/product/details', { params: params }),
      detailsHistory: params => axios.get('/capital/b/p/product/detailsHistory', { params: params }),
      // 随借随还产品列表
      getListRel: params => axios.get('/capital/b/p/product/list_rel', { params: params })
    }
  },
  cfcaSign: {
    isContractAccount: params => axios.get('/capital/b/p/cfcaSign/isContractAccount', { params: params }),
    accountDetail: params => axios.get('/capital/b/p/cfcaSign/accountDetail', { params: params }),
    addContractAccount: data => axios.post('/capital/b/p/cfcaSign/add', data),
    faceAuth: data => axios.post('/capital/b/p/cfcaSign/faceAuth', data),
    sign: data => axios.post('/capital/b/p/cfcaSign/sign', data),
    altOfflineSign: data => axios.post('/capital/b/p/cfcaSign/altOfflineSign', data),
    signSmsSend: params => axios.get('/capital/b/p/cfcaSign/signSmsSend', { params: params }),
    signFaceAuth: data => axios.post('/capital/b/p/cfcaSign/signFaceAuth', data),
    signFaceCheck: contractId => axios.get('/capital/b/p/cfcaSign/signFaceCheck?contractId=' + contractId),
    accountSmsSend: params => axios.get('/capital/b/p/cfcaSign/accountSmsSend', { params: params }),
    accountSmsValidCode: validCode => axios.get('/capital/b/p/cfcaSign/accountSmsValidCode?validCode=' + validCode),
    signSmsValidCod: validCode => axios.get('/capital/b/p/cfcaSign/signSmsValidCode?validCode=' + validCode),
    responseTrue: params => axios.get('/capital/b/p/cfcaSign/tx2319ResponseTrue', { params: params }),
  },
  warehouse: {
    pageList: params => axios.get('/capital/b/p/warehouse/getWarehouseMasterInfoList', { params: params }),
    warehouseDetail: params => axios.get('/capital/b/p/warehouse/getWarehouseDetail', { params: params })
  },
  aluminum: {
    pageList: params => axios.get('/capital/b/p/aluminum/getList', { params: params }),
    addAluminum: price => axios.put('/capital/b/p/aluminum/add?price=' + price)
  },
  invoice: {
    // 获取发票信息列表
    pageList: params => axios.get('/capital/b/p/firm/invoice/list', { params: params }),
    invoiceDetail: id => axios.get('/capital/b/p/firm/invoice/details?id=' + id),
  },
  system: {
    employee: {
      userPage: params => axios.get('/capital/b/p/sys-user/page', { params: params }),
      userList: params => axios.get('/capital/b/p/sys-user/list', { params: params }),
      updatePersonalCenter: data => axios.post('/capital/b/p/sys-user/update/personal/center', data),
      addAdminToFirm: (firmCode, employeeCode) => axios.put(`/capital/b/p/sys-user/addAdminToFirm?firmCode=${firmCode}&employeeCode=${employeeCode}`),
      cancelAdminToFirm: (firmCode, employeeCode) => axios.put(`/capital/b/p/sys-user/cancelAdminToFirm?firmCode=${firmCode}&employeeCode=${employeeCode}`),
      allByRole: (appKey, roleCode) => axios.get(`/capital/b/p/sys-user/list/all/by/role?appKey=${appKey}&roleCode=${roleCode}`),
      addUser: data => axios.post('/capital/b/p/sys-user/add', data),
      enable: employeeCode => axios.put('/capital/b/p/sys-user/user/enable?employeeCode=' + employeeCode),
      disable: employeeCode => axios.put('/capital/b/p/sys-user/user/disable?employeeCode=' + employeeCode),
      detail: params => axios.get('/capital/b/p/sys-user/detail', { params: params }),
      firmList: params => axios.get('/capital/b/p/sys-user/firmList', { params: params }),
      getPersonal: params => axios.get('/capital/b/p/sys-user/personal/center', { params: params }),
      updatePassword: data => axios.post('/capital/b/p/sys-user/updatePassword', data),
      updatePhone: (phone, smsCode) => axios.post(`/capital/b/p/sys-user/updatePhone?phone=${phone}&smsCode=${smsCode}`),
      update: data => axios.post('/capital/b/p/sys-user/update', data)
    },
    role: {
      findRolePage: params => axios.get('/capital/b/p/sys-role/role/find/by/employee/page', { params: params }),
      rolePage: params => axios.get('/capital/b/p/sys-role/find/page', { params: params }),
      addRelation: data => axios.post('/capital/b/p/sys-role/role/add/and/relation', data),
      add: data => axios.post('/capital/b/p/sys-role/add', data),
      appRole: (appKey, roleCode, data) => axios.post('/capital/b/p/sys-role/add/relation/to/role?appKey=' + appKey + '&roleCode=' + roleCode, data),
      delete: (appKey, roleCode) => axios.delete(`/capital/b/p/sys-role/delete?appKey=${appKey}&roleCode=${roleCode}`),
      findRole: firmCode => axios.get('/capital/b/p/sys-role/findRole?firmCode=' + firmCode),
      featureList: (appKey, roleCode) => axios.get(`/capital/b/p/sys-role/menu/feature/list?appKey=${appKey}&roleCode=${roleCode}`),
      featureSet: (appKey, roleCode, data) => axios.post('/capital/b/p/sys-role/menu/feature/set?appKey=' + appKey + '&roleCode=' + roleCode, data),
      deleteEmployee: (appKey, roleCode, employeeCode) => axios.delete(`/capital/b/p/sys-role/role/delete?appKey=${appKey}&roleCode=${roleCode}&employeeCode=${employeeCode}`),
      update: data => axios.put('/capital/b/p/sys-role/update', data)
    },
    org: {
      add: data => axios.post('/capital/b/p/sys-org/add', data),
      delete: orgCode => axios.delete('/capital/b/p/sys-org/delete?orgCode=' + orgCode),
      addEmployee: (orgCode , data) => axios.put('/capital/b/p/sys-org/employee/add/list?orgCode=' + orgCode, data),
      listEmployee: orgCode => axios.get('/capital/b/p/sys-org/employee/list?orgCode=' + orgCode),
      removeEmployee: (orgCode, employeeCode) => axios.put(`/capital/b/p/sys-org/employee/remove?orgCode=${orgCode}&employeeCode=${employeeCode}`),
      list: params => axios.get('/capital/b/p/sys-org/list', { params: params }),
      update: data => axios.post('/capital/b/p/sys-org/update', data)
    },
    menu: {
      menuList: params => axios.get('/capital/b/p/sys-menu/all/feature/list', { params: params })
    },
    account: {
      getAccountList: params => axios.get('/capital/b/p/eaccount/list', { params: params }),
      getTakeEffectList: params => axios.get('/capital/b/p/eaccount/listTakeEffect', { params: params }),
      addAccount: data => axios.post('/capital/b/p/eaccount/add', data),
      zjBankCodeList: params => axios.get('/capital/b/p/eaccount/zjBankCodeList', { params: params }),
      getAccountDetail: userId => axios.get('/capital/b/p/eaccount/info?userId=' + userId),
      updateAccount: data => axios.post('/capital/b/p/eaccount/update', data),
      getBankDetail: userId => axios.get('/capital/b/p/eaccount/infoAndBank?userId=' + userId),
      addBank: data => axios.post('/capital/b/p/eaccount/eBank/add', data),
      updateBank: data => axios.post('/capital/b/p/eaccount/eBank/update', data),
      zhongUrl: params => axios.get('/capital/b/p/eaccount/zhongUrl', { params: params }),
      eJz4611: userId => axios.post('/capital/b/p/eaccount/zhongjin/eJz4611?userId=' + userId),
      relieveEJz4611: (userId, operationFlag) => axios.post(`/core/b/p/eaccount/zhongjin/eJz4611?userId=${userId}&operationFlag=${operationFlag}`),
      eJz4601: userId => axios.post('/capital/b/p/eaccount/zhongjin/eJz4601?userId=' + userId),
      eJz4613: (userId, verifyWay, amount, sMSCode) => axios.post(`/capital/b/p/eaccount/zhongjin/eJz4613?userId=${userId}&verifyWay=${verifyWay}&amount=${amount}&sMSCode=${sMSCode}`),
      eJz7703: userId => axios.post('/capital/b/p/eaccount/zhongjin/eJz7703?userId=' + userId),
      delAccount: userId => axios.post('/capital/b/p/eaccount/del?userId=' + userId),
      eJz4691: userId => axios.get('/capital/b/p/eaccount/zhongjin/eJz4691?userId=' + userId),
      eJz4692: params => axios.get('/capital/b/p/eaccount/zhongjin/eJz4692', { params: params }),
      eJz4643: (userId, amount) => axios.post(`/capital/b/p/eaccount/zhongjin/eJz4643?userId=${userId}&amount=${amount}`),
      eJz4641: (userId, amount, paymentWay, QRPaymentWay, pageURL) => axios.post(`/capital/b/p/eaccount/zhongjin/eJz4641?userId=${userId}&amount=${amount}&paymentWay=${paymentWay}&QRPaymentWay=${QRPaymentWay}&pageURL=${pageURL}`),
      eJz4665: (payeeUserID, bankAccountName, bankAccountNumber) => axios.post(`/capital/b/p/eaccount/zhongjin/eJz4665?payeeUserID=${payeeUserID}&bankAccountName=${bankAccountName}&bankAccountNumber=${bankAccountNumber}&operation=10`),
      getWhitePage: params => axios.get('/capital/b/p/eaccount/whitePage', { params: params }),
      eJz5011: data => axios.post('/capital/b/p/eaccount/zhongjin/eJz5011', data),
    },
    archives: {
      getFirmListDtoList: params => axios.get('/capital/b/p/file/firmListDtoList', {params: params}),
      getCoreDto: firmId => axios.get('/capital/b/p/file/coreDto?firmId=' + firmId),
      getCustomerOne: firmId => axios.get('/capital/b/p/file/customerOne?firmId=' + firmId),
      getCreditDto: creditCode => axios.get('/capital/b/p/file/creditDto?creditCode=' + creditCode),
      getUseDto: useCode  => axios.get('/capital/b/p/file/useDto?useCode=' + useCode),
      coreDownload: firmId => axios.get('/capital/b/p/file/coreDownload?firmId=' + firmId),
      firmDownload: firmId => axios.get('/capital/b/p/file/firmDownload?firmId=' + firmId),
      coreQuotaDownload: firmId => axios.get('/capital/b/p/file/CoreQuotaDownload?firmId=' + firmId),
      creditCoreBasicDownload: firmId => axios.get('/capital/b/p/file/creditCoreBasicDownload?firmId=' + firmId),
      useCoreReDownload: firmId => axios.get('/capital/b/p/file/useCoreReDownload?firmId=' + firmId),
      creditCoreInvoiceDownload: firmId => axios.get('/capital/b/p/file/creditCoreInvoiceDownload?firmId=' + firmId),
      creditCoreDownload: firmId => axios.get('/capital/b/p/file/creditCoreDownload?firmId=' + firmId),
      creditCoreConDownload: firmId => axios.get('/capital/b/p/file/creditCoreConDownload?firmId=' + firmId),
      creditCoreEncDownload: firmId => axios.get('/capital/b/p/file/creditCoreEncDownload?firmId=' + firmId),
      useCoreDownload: firmId => axios.get('/capital/b/p/file/useCoreDownload?firmId=' + firmId),
      useCoreContractDownload: firmId => axios.get('/capital/b/p/file/useCoreContractDownload?firmId=' + firmId),
      useCoreEnclosureDownload: firmId => axios.get('/capital/b/p/file/useCoreEnclosureDownload?firmId=' + firmId),
      useCoreReInvoiceDownload: firmId => axios.get('/capital/b/p/file/useCoreReInvoiceDownload?firmId=' + firmId),
      useCoreCollectionDownload: firmId => axios.get('/capital/b/p/file/useCoreCollectionDownload?firmId=' + firmId),
      useCoreLoanDownload: firmId => axios.get('/capital/b/p/file/useCoreLoanDownload?firmId=' + firmId),
      repaymentReCoDownload: firmId => axios.get('/capital/b/p/file/repaymentreCoDownload?firmId=' + firmId),
      repaymentCoreCoDownload: firmId => axios.get('/capital/b/p/file/repaymentCoreCoDownload?firmId=' + firmId),
      repaymentCorePayDownload: firmId => axios.get('/capital/b/p/file/repaymentCorePayDownload?firmId=' + firmId),
      customerDownload: firmId => axios.get('/capital/b/p/file/customerDownload?firmId=' + firmId),
      customerFirmDownload: firmId => axios.get('/capital/b/p/file/firmDownload?firmId=' + firmId),
      creditCustomerDownload: firmId => axios.get('/capital/b/p/file/creditCustomerDownload?firmId=' + firmId),
      creditCustomerConDownload: firmId => axios.get('/capital/b/p/file/creditCustomerConDownload?firmId=' + firmId),
      creditCustomerEncDownload: firmId => axios.get('/capital/b/p/file/creditCustomerEncDownload?firmId=' + firmId),
      creditCustomerBasicDownload: firmId => axios.get('/capital/b/p/file/creditCustomerBasicDownload?firmId=' + firmId),
      creditCustomerInvoiceDownload: firmId => axios.get('/capital/b/p/file/creditCustomerInvoiceDownload?firmId=' + firmId),
      useCustomerDownload: firmId => axios.get('/capital/b/p/file/useCustomerDownload?firmId=' + firmId),
      useCustomerContractDownload: firmId => axios.get('/capital/b/p/file/useCustomerContractDownload?firmId=' + firmId),
      useCustomerEnclosureDownload: firmId => axios.get('/capital/b/p/file/useCustomerEnclosureDownload?firmId=' + firmId),
      useCustomerReDownload: firmId => axios.get('/capital/b/p/file/useCustomerReDownload?firmId=' + firmId),
      useCustomerReInvoiceDownload: firmId => axios.get('/capital/b/p/file/useCustomerReInvoiceDownload?firmId=' + firmId),
      useCustomerVoucherDownload: firmId => axios.get('/capital/b/p/file/useCustomerLoanDownload?firmId=' + firmId),
      useCustomerCollectionDownload: firmId => axios.get('/capital/b/p/file/useCustomerCollectionDownload?firmId=' + firmId),
      repaymentCustomerDownload: firmId => axios.get('/capital/b/p/file/repaymentCustomerDownload?firmId=' + firmId),
      repaymentCustomerCoDownload: firmId => axios.get('/capital/b/p/file/repaymentCustomerCoDownload?firmId=' + firmId),
      repaymentCustomerPayDownload: firmId => axios.get('/capital/b/p/file/repaymentCustomerPayDownload?firmId=' + firmId),
      creditDownload: creditCode => axios.get('/capital/b/p/file/creditDownload?creditCode=' + creditCode),
      creditCreditDownload: creditCode => axios.get('/capital/b/p/file/creditCreditDownload?creditCode=' + creditCode),
      creditConDownload: creditCode => axios.get('/capital/b/p/file/creditConDownload?creditCode=' + creditCode),
      creditEncDownload: creditCode => axios.get('/capital/b/p/file/creditEncDownload?creditCode=' + creditCode),
      creditBasicDownload: creditCode => axios.get('/capital/b/p/file/creditBasicDownload?creditCode=' + creditCode),
      creditInvoiceDownload: creditCode => axios.get('/capital/b/p/file/creditInvoiceDownload?creditCode=' + creditCode),
      creditUseDownload: creditCode => axios.get('/capital/b/p/file/creditUseDownload?creditCode=' + creditCode),
      useCreditContractDownload: creditCode => axios.get('/capital/b/p/file/useCreditContractDownload?creditCode=' + creditCode),
      useCreditEnclosureDownload: creditCode => axios.get('/capital/b/p/file/useCreditEnclosureDownload?creditCode=' + creditCode),
      useCreditReDownload: creditCode => axios.get('/capital/b/p/file/useCreditReDownload?creditCode=' + creditCode),
      useCreditReInvoiceDownload: creditCode => axios.get('/capital/b/p/file/useCreditReInvoiceDownload?creditCode=' + creditCode),
      useCreditVoucherDownload: creditCode => axios.get('/capital/b/p/file/useCreditLoanDownload?creditCode=' + creditCode),
      useCreditCollectionDownload: creditCode => axios.get('/capital/b/p/file/useCreditCollectionDownload?creditCode=' + creditCode),
      repaymentCreditDownload: creditCode => axios.get('/capital/b/p/file/repaymentCreditDownload?creditCode=' + creditCode),
      repaymentCreditCoDownload: creditCode => axios.get('/capital/b/p/file/repaymentCreditCoDownload?creditCode=' + creditCode),
      repaymentCreditPayDownload: creditCode => axios.get('/capital/b/p/file/repaymentCreditPayDownload?creditCode=' + creditCode),
      useDownload: useCode => axios.get('/capital/b/p/file/useDownload?useCode=' + useCode),
      useUseDownload: useCode => axios.get('/capital/b/p/file/useUseDownload?useCode=' + useCode),
      useContractDownload: useCode => axios.get('/capital/b/p/file/useContractDownload?useCode=' + useCode),
      useEnclosureDownload: useCode => axios.get('/capital/b/p/file/useEnclosureDownload?useCode=' + useCode),
      useReDownload: useCode => axios.get('/capital/b/p/file/useReDownload?useCode=' + useCode),
      useReInvoiceDownload: useCode => axios.get('/capital/b/p/file/useReInvoiceDownload?useCode=' + useCode),
      useLoanDownload: useCode => axios.get('/capital/b/p/file/useLoanDownload?useCode=' + useCode),
      useCollectionDownload: useCode => axios.get('/capital/b/p/file/useCollectionDownload?useCode=' + useCode),
      repaymentDownload: useCode => axios.get('/capital/b/p/file/repaymentDownload?useCode=' + useCode),
      repaymentPayDownload: useCode => axios.get('/capital/b/p/file/repaymentPayDownload?useCode=' + useCode),
      repaymentCoDownload: useCode => axios.get('/capital/b/p/file/repaymentCoDownload?useCode=' + useCode),
    },
    configure: {
      getConfigureDetail: data => axios.post('/capital/sys/configure/detail', data),
    }
  },
  workflow: {
    complete: (taskId, data) => axios.post('/workflow/b/p/sys/task/complete?taskId=' + taskId, data),
    addWorkFlow: data => axios.post('/capital/b/p/work/addWorkFlow', data),
    listPage: params => axios.get('/workflow/b/p/firm/flow/listPage', { params: params }),
    getUserTask: modelId => axios.get('/workflow/b/p/firm/flow/getUserTask?modelId=' + modelId),
    add: data => axios.post('/workflow/b/p/firm/flow/add', data),
    deleteById: modelId => axios.post('/workflow/b/p/firm/flow/deleteById?modelId=' + modelId),
    flowInit: modelId => axios.post('/workflow/b/p/firm/flow/init?modelId=' + modelId),
    save: data => axios.post('/workflow/b/p/firm/flow/save', data),
    details: modelId => axios.post('/workflow/b/p/firm/flow/details?modelId=' + modelId),
    getResourceImag: (modelId, resourceName) => axios.get(`/workflow/b/p/firm/flow/getResourceImage?modelId=${modelId}&resourceName=${resourceName}`),
    getRoleList: firmCode => axios.get('/workflow/b/p/firm/flow/getRoleList?firmCode=' + firmCode),
    getFlowImage: processInstanceId => axios.get('/workflow/b/p/firm/flow/getFlowImage?processInstanceId=' + processInstanceId),
    getFirmList: params => axios.get('/workflow/b/p/firm/flow/getFirmList', { params: params }),
    getDictList: params => axios.get('/workflow/b/p/sys/dict/list', { params: params }),
    getDictListExt: params => axios.get('/workflow/b/p/sys/dict/listExt', { params: params }),
    flowExpor: modelId => axios.post('/workflow/b/p/firm/flow/export?modelId=' + modelId),
    deployById: modelId => axios.post('/workflow/b/p/firm/flow/deployById?modelId=' + modelId),
    deployByTxt: data => axios.post('/workflow/b/p/firm/flow/deploy/by/txt', data)
  },
  operating: {
    listPage: params => axios.get('/capital/b/p/operating/listPage', { params: params })
  },
  dictInfo: {
    selectDictInfo: params  => axios.get('/capital/v1/mstDictInfo/selectDictInfoListByCode', { params: params })
  },
  // 字典类型操作S
  dict: {
    /**
     *  获得全部的字典通过dctTpCd 查询字典数据
     *   dom 样板 <el-select v-model="双向绑定数据" placeholder="请输入">
     <el-option v-for="item in option"
     :key="item.value"
     :label="item.label"
     :value="item.value"
     />
     </el-select>
     *  @param type (platform、client) + dctTpCd 必传 ***
     *  @return {lable: '', value: '', key: ''}
     *
     */
    getDictData (param) {
      const options = []
      if (param) {
        // const url = '/dict/getDict'
        const url = '/' + param.type + '/dict/getDict'
        // const urls = '/' + param.type + '/dict/getDictBatch'
        axios.get(url, { params: param }).then(res => {
          const data = res.data.data
          if (data && data.length > 0) {
            data.forEach(item => {
              options.push({ label: item.dctValNm, value: item.dctVal, key: item.dctKey })
            })
          }
        })
      }
      return options
    },
    /**
     *
     * @param type (platform、client) + dctTpCd 必传 *** + dctVal 必传
     * @return {[]}
     */
    getDictName (param) {
      let optionsName = '-'
      if (param) {
        axios.get('/' + param.type + '/dict/getDict', { params: param }).then(res => {
          const data = res.data.data
          if (data) {
            for (let i = 0; i < data.length; i++) {
              optionsName = data[i].dctValNm
            }
          }
        })
      }
      return optionsName
    },
    /**
     * 获取省市区 - 在一个select
     * dom <el-cascader
     v-model="双向绑定数据"
     :options="optionsJson"
     clearable
     filterable
     />
     */
    getAddress () {
      axios.get('/v1/mstDictInfo/selectDictInfo/area').then(res => {
        const cityData = JSON.stringify(res.data.data)
        const optionsJson = JSON.parse(cityData.replace(/code/g, 'value').replace(/name/g, 'label'))
        optionsJson.map((item, index) => {
          item.children = item.subList
          if (typeof item.subList !== 'undefined' && item.subList !== null) {
            if (item.subList.length > 0) {
              item.children.map((itemN, indexN) => {
                itemN.children = itemN.subList
                delete itemN.subList
              })
            }
          }
        })
        return optionsJson
      })
    }
  }
}
export default api
