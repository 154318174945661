/**
 * system Created by HuZiqiang on 2022/5/17.
 */
export default [
  {
    path: '/quotaManagement/customerQuota',
    name: 'customerQuota',
    meta: {
      requireAuth: false,
      group: '额度管理',
      title: '融资企业额度明细'
    },
    component: () => import('@/views/quotaManagement/customerQuota/list')
  },
  {
    path: '/quotaManagement/coreQuota',
    name: 'coreQuota',
    meta: {
      requireAuth: false,
      group: '额度管理',
      title: '核心企业额度明细'
    },
    component: () => import('@/views/quotaManagement/coreQuota/list')
  },
  {
    path: '/quotaManagement/coreQuota/detail',
    name: 'coreQuotaDetail',
    meta: {
      requireAuth: false,
      group: '额度管理',
      parentName: 'coreQuota',
      title: '详情'
    },
    component: () => import('@/views/quotaManagement/coreQuota/edit')
  },
  {
    path: '/quotaManagement/coreQuota/audit',
    name: 'coreQuotaAudit',
    meta: {
      requireAuth: false,
      group: '额度管理',
      parentName: 'coreQuota',
      title: '审批'
    },
    component: () => import('@/views/quotaManagement/coreQuota/audit')
  }
]
