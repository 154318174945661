const constants = {
  // 表格边框
  border: false,
  // 表格斑马线
  stripe: true,
  // 列对齐
  align: 'left',
  // 列表是否一行展示
  showOverflowTooltip: true,
  // 新增编辑弹窗不关闭
  closeByModelEdit: false,
  // 详情弹窗关闭
  closeByModelDetail: true,
  // 表单默认：
  labelSuffix: '：',
  // 数字输入默认属性
  baseInputNumberProp: {
    controls: false,
    min: 0,
    step: 0.01,
    precision: 2,
    stepStrictly: true
  },
  regExpRules: {
    decimal2: {
      regExp: /^(\d+)(.[0-9]{1,2})?$/,
      message: '最多输入两位小数'
    },
    decimal8: {
      regExp: /^(\d+)(.[0-9]{1,8})?$/,
      message: '最多输入八位小数'
    }
  },
  aluminumItem: {
    goodsName: '铝',
    specifications: '10*30*1000'
  },
  contractItem: {
    name: '电子签章注意事项',
    items: [
      {
        title: '（一）注意四大类不能使用电子签名的情形',
        value: '《中国人民共和国电子签名法》第三条：当事人约定使用电子签名、数据电文的文书，不得仅因为其采用电子签名、数据电文的形式而否定其法律效力。前款规定不适应以下文书：',
        contentItems: [
          {
            value: '涉及婚姻、收养、继承等人身关系的;'
          },
          {
            value: '涉及土地、房屋等不动产权益转让的;'
          },
          {
            value: '涉及停止供水、供热、供气、供电等公用事业服务的;'
          },
          {
            value: '法律、行政法规规定的不适应电子文书的其他情形。'
          }
        ]
      },
      {
        title: '（二）注意电子签名的真实性',
        value: '《电子签名法》第十三条：电子签名同时符合下列条件的，视为可靠的电子签名：',
        contentItems: [
          {
            value: '真实身份：电子签名制作数据用于电子签名时，属于电子签名人专有;'
          },
          {
            value: '真实意愿：签署时电子签名制作数据仅由电子签名人控制;'
          },
          {
            value: '合同未改：签署后对电子签名的任何改动能够被发现;'
          },
          {
            value: '签名未改：签署后对数据电文内容和形式的任何改动能够被发现。'
          }
        ]
      },
      {
        title: '（三）注意电子签名形成及保存的合法性',
        value: '《电子签名法》第四条：能够有形地表现所载内容，并可以随时调取查用的数据电文，视为符合法律、法规要求的书面形式。《电子签名法》第六条：符合下列条件的数据电文，视为满足法律、法规规定的文件保存要求：',
        contentItems: [
          {
            value: '能够有效地表现所载内容并可供随时调取查用;'
          },
          {
            value: '数据电文的格式与其生成、发送或者接收时的格式相同，或者格式不相同但是能够准确表现原来生成、发送或者接收的内容;'
          },
          {
            value: '能够识别数据电文的发件人、收件人以及发送、接收的时间;'
          }
        ]
      }
    ]
  },
  contractLabelName: [
    { label: '个人征信授权书', value: 'grzx' },
    { label: '企业征信授权书', value: 'qyzx' },
    { label: '平台云贷业务授权书（企业版）', value: 'ptyd' },
    { label: '平台云贷业务个人敏感信息授权书', value: 'mgxx' },
    { label: '平台云贷业务授权书（自然人借款人版）', value: 'zsqs' },
    { label: '授权书', value: 'sqss' },
    { label: '平台云贷借款合同', value: '001' },
    { label: '贷款申请书', value: '002' },
    { label: '平台云贷推荐确认表', value: '003' },
    { label: '受托支付确认书', value: '004' },
    { label: '购销合同', value: '005' }
  ],
  // 启用状态
  enableStatus: [
    { label: '已启用', value: 1 },
    { label: '已禁用', value: 0 }
  ],
  ownFunds: [
    { label: '线下', value: 1 },
    { label: '线上', value: 0 }
  ],
  coreQuotaStatus: [
    { label: '保存', value: 0, isShow: 0},
    { label: '申请', value: 1, isShow: 1 },
    { label: '通过', value: 2, isShow: 1 },
    { label: '驳回', value: 3, isShow: 1 },
    { label: '关闭', value: 4, isShow: 1 },
    { label: '失效', value: 5, isShow: 1 }
  ],
  applicantType: [
    { label: '法人', value: 1 },
    { label: '股东', value: 2 },
    { label: '非法人', value: 3 }
  ],
  creditApplicantType: [
    { label: '法人', value: 'LP' },
    { label: '股东、非法人', value: 'SH' }
  ],
  customerState: [
    { label: '提交', value: 1 },
    { label: '平台审批通过', value: 2 },
    { label: '银行审批通过', value: 3 },
    { label: '平台拒绝', value: 4 },
    { label: '银行拒绝', value: 5 }
  ],
  invoiceType: [
    { label: '专票', value: '01' },
    { label: '普票', value: '04' },
    { label: '电⼦票', value: '10' }
  ],
  invoiceStatus: [
    { label: '已使用', value: 'Y' },
    { label: '未使用', value: 'N' },
    { label: '使用中', value: 'E' }
  ],
  employeeStatus: [
    { label: '已启用', value: '20' },
    { label: '已禁用', value: '10' }
  ],
  flowStateCode: [
    { label: '申请', value: 'Wait' },
    { label: '补件', value: 'UseSupplement' },
    { label: '审批', value: 'UseAudit' },
    { label: '放款', value: 'UseAmount' },
    { label: '待还款', value: 'Repaid' },
    { label: '完成', value: 'Fail' },
    { label: '关闭', value: 'Completed' }
  ],
  distCompanyNameType: [],
  labelType: [
    { label: '高风险', value: '21' },
    { label: '其他', value: '01' }
  ],
  loanFlowStateCode: [
    { label: '待补件', value: 'supplement' },
    { label: '待确认', value: 'confirm' },
    { label: '合同待上传', value: 'contract' },
    { label: '审核中', value: 'toExamine' },
    { label: '已拒绝', value: 'refuse' },
    { label: '还款中', value: 'repayment' },
    { label: '已结清', value: 'settle' }
  ],
  ifFlag: [
    { label: '是', value: true },
    { label: '否', value: false }
  ],
  warehouseStatus: [
    { label: '未使用', value: 'N' },
    { label: '已使用', value: 'Y' },
    { label: '使用中', value: 'E' }
  ],
  informationType: [
    { label: '注册', value: '70' },
    { label: '授信', value: '50' },
    { label: '贷款', value: '20' }
  ],
  messageStatus: [
    { label: '已读', value: 1 },
    { label: '未读', value: 0 }
  ],
  currentStatus: [
    { label: '未还款', value: 'Wait' },
    { label: '部分还款', value: 'Part' },
    { label: '还款中', value: 'Hand' },
    { label: '还款完成', value: 'Finish' },
    { label: '已逾期', value: 'Expected' }
  ],
  messageType: [
    { label: '平台消息', value: '0' },
    { label: '业务消息', value: '1,2,3,4,5,6' }
  ],
  assetsContractStatus: [
    { label: '未使用', value: 0 },
    { label: '已使用', value: 1 }
  ],
  authenticRightStatus: [
    { label: '未确认', value: 0 },
    { label: '已确认', value: 1 }
  ],
  modelType: [
    { label: '授信', value: 'credit', type: 1 },
    { label: '用信', value: 'use', type: 1 },
    { label: '其他', value: 'other', type: 0 }
  ],
  rollType: [
    { label: '是', value: 'yes' },
    { label: '否', value: 'no' }
  ],
  documentType: [
    { label: '居民身份证', value: 1 },
    { label: '护照', value: 2 },
    { label: '港澳居民往来内地通行证', value: 3 },
    { label: '台湾居民往来内地通行证', value: 4 }
  ],
  //还款详情中的表单状态
  recordStatus: [
    { label: '待还款', value: 'Wait' },
    { label: '部分还款', value: 'Part' },
    { label: '还款中', value: 'Hand' },
    { label: '确认中', value: 'Confirm' },
    { label: '业务关联', value: 'Business' },
    { label: '还款完成', value: 'Finish' }
  ],
  contractType: [
    { label: '授信合同', value: 1 },
    { label: '贷前合同', value: 2 }
  ],
  allFinishState: [
    { label: '待签署', value: 0 },
    { label: '部分签署', value: 2 },
    { label: '待生效', value: 3 },
    { label: '已签署', value: 1 },
    { label: '已作废', value: 4 }
  ],
  loanAccStatus: [
    { label: '正常', value: '1' },
    { label: '已结清', value: '2' }
  ],
  productType: [
    { label: '应收账款融资', value: 1 }
  ],
  companyType: [
    { label: '核心企业', value: 'finance-frame-core', states: 1 },
    { label: '融资企业', value: 'finance-frame-customer', states: 1 },
    { label: '资金方', value: 'finance-frame-capital', states: 1 },
    { label: '运营方', value: 'finance-frame-channel', states: 0 }
  ],
  isOnLine: [
    { label: '是', value: 0 },
    { label: '否', value: 1 }
  ],
  repaymentType: [
    { label: '随借随还', value: 'borrowing' },
    { label: '先息后本', value: 'firstRest' },
    { label: '定期还息', value: 'regular' }
  ],
  collectionCompanyType: [
    { label: '资金方', value: 'Capitap' },
    { label: '融资企业', value: 'Customer' }
  ],
  signingMethodType: [
    { label: '线上', value: 1 },
    { label: '线下', value: 0 }
  ],
  productState: [
    { label: '待生效', value: 'Wait' },
    { label: '已上架', value: 'BeenOn' },
    { label: '已下架', value: 'BeenOff' }
  ],
  quotaApplyStatus: [
    { label: '保存', value: 0 },
    { label: '申请', value: 1 },
    { label: '通过', value: 2 },
    { label: '驳回', value: 3 },
    { label: '关闭', value: 4 },
    { label: '失效', value: 5 }
  ],
  legalCredentialType: [
    { label: '居民身份证', value: '0'},
    { label: '外国护照', value: '2' },
    { label: '港澳居民往来内地通行证', value: '5' },
    { label: '台湾居民往来内地通行证', value: '6' }
  ],
  accountUserType: [
    { label: '企业账户', value: '12' },
    { label: '个人账户', value: '11' },
    { label: '个体工商账户', value: '13' }
  ],
  userType: [
    { label: '个人用户', value: '10' },
    { label: '个体工商用户', value: '15' },
    { label: '企业用户', value: '20' },
    { label: '匿名用户', value: '40' }
  ],
  eJz4692OperationType: [
    { label: '收入', value: '10' },
    { label: '支出', value: '20' },
    { label: '冻结', value: '30' },
    { label: '冲正', value: '40' },
    { label: '在途转账', value: '50' },
    { label: '解冻', value: '60' }
  ],
  personalType: [
    { label: '企业法定代表人', value: '12' },
    { label: '经办人', value: '11' }
  ],
  firmScaleType: [
    { label: '大型', value: '01' },
    { label: '中型', value: '02' },
    { label: '小型', value: '03' },
    { label: '微型', value: '04' },
    { label: '其他', value: '98' }
  ],
  bankAccountType: [
    { label: '个人账户', value: '11' },
    { label: '企业账户', value: '12' }
  ]
}
export default constants
