import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Element from 'element-ui'
import feroToolkit from 'fero-toolkit-vue'
import 'element-ui/lib/theme-chalk/index.css'
import Meta from 'vue-meta'
import _ from 'lodash'
import './assets/css/global.less'
import utils from '@/assets/js/utils'
import projectUtils from '@/assets/js/project-utils'
import constants from '@/assets/js/constants'
import api from './assets/js/api'

require('promise.prototype.finally').shim()

Vue.use(Element)
Vue.use(feroToolkit)
Vue.use(Meta)

Vue.prototype._ = _
Vue.prototype.utils = utils
Vue.prototype.projectUtils = projectUtils
Vue.prototype.constants = constants
Vue.prototype.api = api
Vue.prototype.rulesToolkit = feroToolkit.rulesToolkit
Vue.config.productionTip = false

new Vue({
  router,
  store,
  beforeCreate: () => {
    store.commit('updateEmpInfo')
  },
  render: h => h(App)
}).$mount('#app')
