/**
 * utils Created by 刘思尧 on 2018/1/3.
 */
import constants from './constants'
import _ from 'lodash'
import BigNumber from 'bignumber.js'
import moment from 'moment'
import axios from 'axios'
import Vue from 'vue'
import api from '@/assets/js/api'
import Url from 'url-parse'
import {JSEncrypt} from "jsencrypt";
const utils = {
  // 去掉字符串前后空格
  filterTrimValue (originalData) {
    const newData = {}
    const filter = (data) => {
      Object.keys(data).forEach(key => {
        const item = data[key]
        if (!(item === undefined || item === null || item === '')) {
          if (_.isString(item)) {
            newData[key] = _.trim(item)
          } else {
            newData[key] = item
          }
        }
      })
    }
    filter(originalData)
    return newData
  },
  valFormat: function (s, b, unit = '') {
    if (s !== undefined && s !== null) {
      if (!isNaN(parseFloat(s))) {
        return s + ' ~ ' + b + ' ' + unit
      } else {
        return '-'
      }
    } else {
      return '-'
    }
  },
  /**
   * 单字段模糊搜索
   * @param {String} query 查询条件
   * @param {Object[]} list 搜索数据源
   * @param {String} path 要查询的字段属性名
   * @returns {*[]} 过滤后的结果
   */
  fuzzyQuery (query, list, path) {
    const reg = new RegExp(query)
    const arr = []
    list.forEach(item => {
      if (reg.test(item[path])) {
        arr.push(item)
      }
    })
    return arr
  },
  /**
   * axios 返回流触发下载
   * @param {Object} result axios 返回的完整结果
   * @param {String} defaultName 默认文件名
   */
  exportFile (result, defaultName) {
    const link = document.createElement('a')
    let blob = new Blob([result.data], { type: 'application/x-www-form-urlencoded' });
    const contentDisposition = result.headers['content-disposition'] || ''
    let fileName = defaultName
    if (contentDisposition && /filename=.*/ig.test(contentDisposition)) {
      const title = contentDisposition.match(/filename=.*/ig)
      fileName = decodeURI(title[0].split('=')[1]) || defaultName
    }
    if (window.navigator.msSaveBlob) { //判断了有该方法即为IE浏览器
      try {
        window.navigator.msSaveBlob(blob, fileName)
      } catch (e) {
        console.log(e);
      }
    } else {
      link.href = window.URL.createObjectURL(new Blob([result.data]))
      link.target = '_blank'
      link.download = fileName
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  },
  /**
   * 切换必填
   * @description 直接修改规则对象，必填校验必须放在每个规则的首位
   * @param {Boolean} status 要切换到的状态
   * @param {Object} rules 规则集
   * @param {Object} [options={}] 配置项
   * @param {String[]} [options.ignore=[]] 忽略属性
   * @param {String[]} [options.trueIgnore=[]] 切换为必填时忽略属性
   * @param {String[]} [options.falseIgnore=[]] 切换为非必填时忽略属性
   * @returns {*} 修改后的规则集
   */
  requiredChange (status, rules, options = {}) {
    const settings = Object.assign({ ignore: [], trueIgnore: [], falseIgnore: [] }, options)
    const change = (rule) => {
      for (const item of Object.keys(rule)) {
        if (settings.ignore.includes(item) || (status === true && settings.trueIgnore.includes(item)) || (status === false && settings.falseIgnore.includes(item))) {
          continue
        }
        if (_.isArray(rule[item])) {
          if (rule[item][0].required !== undefined) {
            rule[item][0].required = status
          }
        } else {
          change(rule[item])
        }
      }
    }
    change(rules)
    return rules
  },
  /**
   * 获取/更新字典表
   * @param {boolean} [force] 是否强制更新字典表为 true 强制更新
   * @returns {Promise<unknown>}
   */
  getDict (force) {
    return new Promise((resolve, reject) => {
      const format = (result) => {
        result.forEach(item => {
          item.label = item.dctValNm
          item.value = item.dctVal
        })
        const constantsGroup = _.groupBy(result, 'dctTpCd')
        const allConstants = { ...constantsGroup, ...constants }

        Vue.prototype.constants = allConstants
        resolve(allConstants)
      }
      const localDict = localStorage.getItem('dict')
      if (localDict && force !== true) {
        format(JSON.parse(localDict))
      } else {
        api.base.dataDict().then(result => {
          localStorage.setItem('dict', JSON.stringify(result.data.value))
          format(result.data.value)
        })
      }
    })
  },
  /**
   * 数字格式化
   * @param value
   * @param {object} [config] 配置项
   * @param {string} [config.replaceString = '-'] 无有效值默认值展示
   * @param {string} [config.dividedBy] 除数
   * @param {string} [config.dp] 小数保留位数
   * @returns {string|*|string}
   */
  numberFormat: function (value, config = {}) {
    let x = new BigNumber(value)
    if (x.isNaN()) {
      return (config.replaceString || '-')
    }
    if (config.dividedBy) {
      x = x.dividedBy(config.dividedBy)
    }
    return x.toFormat(config.dp)
  },
  // 金额格式化
  moneyFormat (value, dp = 2) {
    return utils.numberFormat(value, { dp })
  },
  // 表格金额格式化
  tableMoneyFormat (row, column, tableCell) {
    return utils.moneyFormat(tableCell)
  },
  encryptRSA(str) {
    const encryptor = new JSEncrypt() // 新建JSEncrypt对象
    const publicKey = 'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCEGENnf3rdiO20isoLQqezw12FoWXII9FBw8nR1MWQ3X0CVzOsqY1hOmxD/YI9OB7WVIaVax5tj1l+wk6A0v85Z4OpGWqz4B5L3fCUlBwf/M6DXHlSN1OZttvQF3OeWvc6gvJHihR7pp18zc4KfCJx0Ry6IrGH/2SNOVE1AIgvRQIDAQAB';  //公钥串
    encryptor.setPublicKey(publicKey) // 设置公钥
    const rsaPassWord = encryptor.encrypt(str)
    return rsaPassWord
  },
  // 表格金额格式化
  applyTableMoneyFormat (row) {
    const data = row || {}
    let money = 0
    if (data.passAmount) {
      money = data.passAmount
    } else {
      money = data.applyAmount
    }
    return utils.moneyFormat(money)
  },
  formatMessageType (val) {
    let content = ''
    if (Number(val) === 0) {
      content = '平台消息'
    } else {
      content = '业务消息'
    }
    return content
  },
  formatBankNo(accNo) {
    console.log(accNo)
    if (accNo) {
      let result = '', index = 0
      if (accNo != undefined && accNo != null) {
        for (let i = 0; i < accNo.length; i++) {
          result += accNo.charAt(i)
          index++
          if (index == 4 && (i + 1 != accNo.length)) {
            result += " "
            index = 0
          }
        }
      }
      return result
    } else {
      return '-'
    }
  },
  formatDecimal (str, num) {//str当前输入值，num是想要保留的小数位数
    str = String(str)
    if (!str.trim()) {
      return str
    }
    let len1 = str.substr(0, 1)
    let len2 = str.substr(1, 1)
    // 如果第一位是0，第二位不是点，就用数字把点替换掉
    if (str.length > 1 && len1 === 0 && len2 !== '.') {
      str = str.substr(1, 1)
    }
    // 第一位不能是.
    if (len1 === '.') {
      str = ''
    }
    // 限制只能输入一个小数点
    if (str.indexOf('.') !== -1) {
      let str_ = str.substr(str.indexOf('.') + 1)
      if (str_.indexOf('.') !== -1) {
        str = str.substr(0, str.indexOf('.') + str_.indexOf('.') + 1)
      }
    }
    // 正则替换
    str = str.replace(/[^\d^.]+/g, '') // 保留数字和小数点
    if (num || num === 0) {
      let rest = str.split('.')[1]
      if (rest && rest.length > num) {
        rest = rest.substr(0, num)
        str = str.split('.')[0] + '.' + rest
      }
    }
    return str
  },
  /**
   * 时间格式化
   * @param {string|timestamp|Date} date 日期
   * @param {string} [r='-'] 无数据展示值
   * @returns {string|string} 格式化后时间
   */
  dateTimeFormat (date, r) {
    return date ? moment(date).format('YYYY-MM-DD HH:mm:ss') : (r || '-')
  },
  /**
   * 日期格式化
   * @param {string|timestamp|Date} date 日期
   * @param {string} [r='-'] 无数据展示值
   * @returns {string|string} 格式化后时间
   */
  dateFormat (date, r) {
    return date ? moment(date).format('YYYY-MM-DD') : (r || '-')
  },
  formatDate (date) {
    let d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  },
  /**
   * 空数据格式化
   * @param value
   * @param {string} [r='-']
   * @returns {string|*}
   */
  isEffectiveCommon (value, r = '-') {
    try {
      if (value === undefined || value == null || value === '') {
        return r
      }
      return value
    } catch (e) {
      return r
    }
  },
  // 仅用于element-ui table组件的column列格式化空数据
  isEffective (row, column, tableCell) {
    return utils.isEffectiveCommon(tableCell)
  },
  // 获取常量
  findConstants: function (type, obj, defaultValue = {}) {
    return _.find(constants[type], obj) || defaultValue
  },
  // 字典分类
  distCompanyNameType (force) {
    const companyNameGroup = []
    force.forEach(item => {
      companyNameGroup.push({
        label: item.name,
        value: item.code
      })
    })
    constants.distCompanyNameType = companyNameGroup
  },
  // 角色值转换
  roleFormat (value, options = {}) {
    const roleList = value || []
    if (_.isEmpty(roleList)) {
      return '-'
    } else {
      let data = []
      roleList.forEach(item => {
        data.push(item.roleName)
      })
      return data.join(',')
    }
  },
  formatHtmlLabel (val) {
    let content = ''
    if (val) {
      content = val.replace(/<[^>]+>/g, '');
    } else {
      content = '-'
    }
    return content
  },
  // 状态值转换
  statusFormat (value, type, options = {}) {
    const settings = Object.assign({ valueKey: 'value', labelKey: 'label' }, options)
    let data = {}
    const query = {}
    query[settings.valueKey] = value
    data = _.find(Vue.prototype.constants[type], query)
    return _.get(data, settings.labelKey, '-')
  },
  // 状态值转换
  statusFormatFilter (value, type, options = {}) {
    const settings = Object.assign({ valueKey: 'value', labelKey: 'label' }, options)
    let data = {}
    const query = {}
    query[settings.valueKey] = value
    data = _.find(_.filter(Vue.prototype.constants[type], options), query)
    return _.get(data, settings.labelKey, '-')
  },
  tableStatusFormat (type, options) {
    return function (row, col, cell) {
      return utils.statusFormat(cell, type, options)
    }
  },
  getSelectStatus (type, options) {
    const setting = Object.assign({ jumpNoStatus: true }, options)
    const list = Vue.prototype.constants[type] || []
    const enableList = []
    if (setting.filter) {
      list.forEach(item => {
        if (setting.filter(item)) {
          enableList.push(item)
        }
      })
    } else {
      list.forEach(item => {
        if (item.stCd === 1 || (setting.jumpNoStatus && item.stCd === undefined)) {
          enableList.push(item)
        }
      })
    }
    return enableList
  },
  // 下载方法
  downloadP(bucket, path) {
    const data = {
      bucketParams: this.encryptRSA(bucket),
      objectKeyParams: this.encryptRSA(path)
    }
    api.open.downloadP(data).then(result => utils.exportFile(result)).finally(() => {}).finally(() => {})
  },
  // 下载方法
  downloadFile(url, filename) {
    this.getBlob(url).then((blob) => {
      this.saveAs(blob, filename)
    })
  },
  /**
   * 获取 blob
   * @param  {String} url 目标文件地址
   * @return {Promise}
   */
  getBlob(url) {
    return new Promise((resolve) => {
      const xhr = new XMLHttpRequest()
      xhr.open('GET', url, true)
      xhr.responseType = 'blob'
      xhr.onload = () => {
        if (xhr.status === 200) {
          resolve(xhr.response)
        }
      }
      xhr.send()
    })
  },
  /**
   * 保存
   * @param  {Blob} blob
   * @param  {String} filename 想要保存的文件名称
   */
  saveAs(blob, filename) {
    if (window.navigator.msSaveOrOpenBlob) {
      navigator.msSaveBlob(blob, filename)
    } else {
      const link = document.createElement('a')
      const body = document.querySelector('body')
      link.href = window.URL.createObjectURL(blob)
      link.download = filename
      link.style.display = 'none'
      body.appendChild(link)
      link.click()
      body.removeChild(link)
      window.URL.revokeObjectURL(link.href)
    }
  },
  getExplorer() {
    let explorer = window.navigator.userAgent,
      compare = function (s) {
        return (explorer.indexOf(s) >= 0);
      },
      ie11 = (function () {
        return ('ActiveXObject' in window)
      })()
    if (compare('MSIE') || ie11) {
      return 'ie'
    } else if (compare('Firefox') && !ie11) {
      return 'Firefox'
    } else if (compare('Chrome') && !ie11) {
      if (explorer.indexOf('Edge') > -1) {
        return 'Edge'
      } else {
        return 'Chrome'
      }
    } else if (compare('Opera') && !ie11) {
      return 'Opera'
    } else if (compare('Safari') && !ie11) {
      return 'Safari'
    }
  },
  // 审批消息通用处理url
  urlParser (data) {
    const viewUrl = Url(data.viewUrl, window.location, false)
    const pathnameArr = viewUrl.pathname.split('/')
    pathnameArr[pathnameArr.length - 1] = 'detail'
    viewUrl.set('pathname', pathnameArr.join('/'))
    if (!viewUrl.query) {
      viewUrl.query = `?id=${data.businessId}`
    }
    return viewUrl.pathname + viewUrl.query
  },
  trueFalseFormant (value, options = {}) {
    const settings = Object.assign({ true: '是', false: '否', empty: '-' }, options)
    if (value === undefined || value === null || value === '') {
      return settings.empty
    } else {
      if (Number(value) === 1) {
        return settings.true
      } else {
        return settings.false
      }
    }
  },
  // 时间拆分
  computedDate (startTime, endTime) {
    return {
      get () {
        return [this.searchForm[startTime], this.searchForm[endTime]]
      },
      set (newValue) {
        if (newValue) {
          this.searchForm[startTime] = newValue[0]
          this.searchForm[endTime] = newValue[1]
        } else {
          this.searchForm[startTime] = ''
          this.searchForm[endTime] = ''
        }
      }
    }
  },
  // 产品下拉选label
  goodsLabel (val) {
    // 多字段拼接（拼接之前去掉空值）
    return _.toString(_.compact([val.goodsName, val.goodsClassifyParentName, val.goodsClassifyName, val.goodsSpecs, val.goodsModel, val.goodsCode]))
  },
  // 数字转大写
  numberToUpper (num) {
    // 汉字的数字
    const cnNums = new Array(0)
    cnNums.push('零', '一', '二', '三', '四', '五', '六', '七', '八', '九')
    // 基本单位
    const cnIntRadice = new Array(0)
    cnIntRadice.push('', '十', '百', '千')
    // 对应整数部分扩展单位
    const cnIntUnits = new Array(0)
    cnIntUnits.push('', '万', '亿', '兆')
    // 最大处理的数字
    const maxNum = 999999999999999.9999
    // 金额整数部分
    let integerNum
    // 金额小数部分
    let decimalNum
    // 输出的中文金额字符串
    let chineseStr = ''
    // 分离金额后用的数组，预定义
    let parts
    if (num === '') { return '' }
    num = parseFloat(num)
    if (num >= maxNum) {
      // 超出最大处理数字
      return ''
    }
    if (num === 0) {
      chineseStr = cnNums[0]
      return chineseStr
    }
    // 转换为字符串
    num = num.toString()
    if (num.indexOf('.') === -1) {
      integerNum = num
      decimalNum = ''
    } else {
      parts = num.split('.')
      integerNum = parts[0]
      decimalNum = parts[1].substr(0, 4)
    }
    // 获取整型部分转换
    if (parseInt(integerNum, 10) > 0) {
      let zeroCount = 0
      const IntLen = integerNum.length
      for (let i = 0; i < IntLen; i++) {
        const n = integerNum.substr(i, 1)
        const p = IntLen - i - 1
        const q = p / 4
        const m = p % 4
        if (n === '0') {
          zeroCount++
        } else {
          if (zeroCount > 0) {
            chineseStr += cnNums[0]
          }
          // 归零
          zeroCount = 0
          chineseStr += cnNums[parseInt(n)] + cnIntRadice[m]
        }
        if (m === 0 && zeroCount < 4) {
          chineseStr += cnIntUnits[q]
        }
      }
    }
    // 小数部分
    if (decimalNum !== '') {
      const decLen = decimalNum.length
      for (let i = 0; i < decLen; i++) {
        const n = decimalNum.substr(i, 1)
        if (n !== '0') {
          chineseStr += cnNums[Number(n)]
        }
      }
    }
    if (chineseStr === '') {
      chineseStr += cnNums[0]
    }
    return chineseStr
  },
  // 更新个性化配置信息
  getPersonalization (force) {
    return new Promise((resolve, reject) => {
      const dataJson = localStorage.getItem('personalization')
      if (dataJson && force !== true) {
        resolve(JSON.parse(dataJson))
      } else {
        axios.post('/capital/sys/configure/detail').then(res => {
          localStorage.setItem('personalization', JSON.stringify(res.data.data))
          resolve(res.data.data)
        }).catch(e => {
          reject(e)
        })
      }
    })
  },
  // 更新省市区数据
  getProvinceCityInfo (force) {
    return new Promise((resolve, reject) => {
      const dataJson = localStorage.getItem('provinceCity')
      if (dataJson && force !== true) {
        resolve(JSON.parse(dataJson))
      } else {
        axios.get('/capital/v1/mstDictInfo/selectDictInfo/area').then(res => {
          const cityData = JSON.stringify(res.data.data)
          const optionsJson = JSON.parse(cityData.replace(/code/g, 'value').replace(/name/g, 'label'))
          optionsJson.map((item, index) => {
            item.children = item.subList
            if (typeof item.subList !== 'undefined' && item.subList !== null) {
              if (item.subList.length > 0) {
                item.children.map((itemN, indexN) => {
                  itemN.children = itemN.subList
                })
              }
            }
          })
          const data = optionsJson || []
          const addData = {
            province: [],
            city: [],
            area: []
          }
          data.forEach(itProvince => {
            const data = {
              key: itProvince.value,
              label: itProvince.label,
              parentCode: '-1'
            }
            addData.province.push(data)
            if (itProvince.children) {
              itProvince.children.forEach(itCity => {
                const data = {
                  key: itCity.value,
                  label: itCity.label,
                  parentCode: itCity.parentCode
                }
                addData.city.push(data)
                if (itCity.children) {
                  itCity.children.forEach(itArea => {
                    const data = {
                      key: itArea.value,
                      label: itArea.label,
                      parentCode: itArea.parentCode
                    }
                    addData.area.push(data)
                  })
                }
              })
            }
          })
          localStorage.setItem('provinceCity', JSON.stringify(addData))
          resolve(res.data.data)
        }).catch(e => {
          reject(e)
        })
      }
    })
  },
  // 省市区名称转换
  provinceCityFormatter (codeArr, delimiter = ' ') {
    this.getProvinceCityInfo().then(result => {
      const detailList = []
      detailList[0] = _.get(_.find(result.province, { key: codeArr[0] }), 'label', '')
      detailList[1] = _.get(_.find(result.province, { key: codeArr[1] }), 'city', '')
      detailList[2] = _.get(_.find(result.province, { key: codeArr[2] }), 'area', '')
      detailList[3] = codeArr[3]
      return detailList.join(delimiter)
    })
    return ''
  },
  // 省市区名称转换
  newProvinceCityFormatter (codeArr, delimiter = ' ') {
    if (localStorage.getItem('provinceCity')) {
      const dataJson = JSON.parse(localStorage.getItem('provinceCity'))
      const detailList = []
      detailList[0] = _.get(_.find(dataJson.province, { key: codeArr[0] }), 'label', '')
      detailList[1] = _.get(_.find(dataJson.city, { key: codeArr[1] }), 'label', '')
      detailList[2] = _.get(_.find(dataJson.area, { key: codeArr[2] }), 'label', '')
      detailList[3] = codeArr[3]
      return detailList.join(delimiter)
    } else {
      this.provinceCityFormatter(codeArr)
    }
  }
}
export default utils
